@import "@appkit4/styles/appkit.min.css";
@import "@appkit4/react-components/dist/styles/appkit4-react.min.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-Text-Heading: var(--Text-Heading, #252525);
    --color-Text-Body: var(--Text-Body, #474747);
    --color-Text-Secondary: var(--Text-Secondary, #fff);
    --color-Text-Light: var(--Text-Light, #696969);
    --color-Text-Primary: var(--Text-Primary, #415385);

    --color-Yellow-Lighter: var(--data-yellow-lighter, #ffecbd);
    --color-Background-Hover: var(--Background-Hover, #f3f3f3);
    --color-Background-Selected-hover: var(--Background-Selected-hover, #e8e8e8);
    --color-Background-Primary: var(--Background-Primary, #415385);
    --color-Background-Border: var(--Background-Border, #d1d1d1);
    --color-Background-Skeleton: #e6e6e6;
    --Background-Container-alt: var(--Background-Container-alt, #f3f3f3);

    --States-error-1: var(--states-error-01, #f3d4d1);
    --States-error-5: var(--states-error-05, #c52a1a);
  }
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  width: 8px;
}
*::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: transparent;
}

*::-webkit-scrollbar-track:hover {
  background-color: transparent;
}

*::-webkit-scrollbar-track:active {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: var(--color-background-triple-hover);
}

*::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-background-triple-hover);
}

*::-webkit-scrollbar-thumb:active {
  background-color: var(--neutral-10, #979797);
}
@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
.ap-field .ap-field-title-container .ap-field-title {
  white-space: normal !important;
}
